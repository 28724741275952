//import { useEffect } from "react";
//import publicIp from "public-ip";

// Components
import { Header } from "./components/Header/index";
import {
  CalceTest,
  CalceBlanche,
} from "./art/index";

// An array of Art Components
let componentArr = [
  <CalceTest />,
  <CalceBlanche />,
];

function App() {
//  useEffect(() => {
//    async function getIP() {
//      window.ip_address = await publicIp.v4();
//      window.ip_address = window.ip_address.replace(
//        /[&/\\#,+()$~%.'":*?<>{}]/g,
//        ""
//      );
//    }
//    getIP();
//  }, []);
  return (
    <>
      <Header />
      <div className="grid-container full">
        <div className="grid-x">
          {componentArr.map((component, index) => {
            return (
              <div className="cell large-6" key={index} index={index}>
                {component}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default App;
