import style from "./style.module.css";

export function Header() {

  return (
    <div className={style.header}>
      <div className={`${style.container} grid-container`}>
        <div className={style.title}>
          <p className={style.titleText}>Stéphane Calce</p>
        </div>
            <a href="about.html">About</a>
      </div>
    </div>
  );
}
