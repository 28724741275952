import style from "./style.module.css";
import React from 'react'

function SC() {
  // Enter your name, github url and art-name below
//  let data = {
 //   name: "",
   // "gh-link": "https://vimeo.com/361674438",
   // "art-name": "",

//  };
  return (
    <div className={`${style.container} container`}>
      {/* DO NOT edit the line above*/}

      {/* your art blocks will come here */} 

<div className={`${style.wrapper} wrapper`}>
    <img src="fleuve.png" alt=""width={460} heigth={400} />
    <div className={`${style.centered} centered`}><a href="https://stephanecalce.com/sound">Sound Design-Compositions</a>
</div>
</div>

  {/* do not edit the line below */}
    </div>
  );
}

export default SC;
